import { createRouter, createWebHashHistory } from 'vue-router';
import { cookieAsObject } from '@/utils/cookieToObject';
import store from '@/store/index';

function requireCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const accessToken = cookieObject.accessToken;
  if (accessToken == null) {
    next({ name: 'Login' });
  } else {
    next();
  }
}

function requireLoginCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const accessToken = cookieObject.accessToken;
  if (accessToken == null) {
    next();
  } else {
    next({ name: 'HomeView' });
  }
}


const routes = [
  {
    path: '/',
    name: 'Start',
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: requireLoginCookie
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: requireCookie
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/forgot-password/:token',
    name: 'ForgotPasswordConfirm',
    component: () => import('@/views/ForgotPasswordConfirm.vue'),
    props: true
  },
  {
    path: '/update-expired-password/:token/',
    name: 'UpdateExpiredPassword',
    component: () => import('@/views/UpdateExpiredPassword.vue'),
    props: true
  },
  {
    path: '/check-mail',
    name: 'CheckEmail',
    component: () => import('@/views/CheckEmail.vue'),
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('@/views/InboxView.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import('@/views/UserProfile.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: () => import('@/views/AdminView.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-users',
    name: 'AuditUsers',
    component: () => import('@/views/AuditUsers.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-files',
    name: 'AuditDocuments',
    component: () => import('@/views/AuditDocuments.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-events',
    name: 'AuditEvents',
    component: () => import('@/views/AuditEvents.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/files',
    name: 'DocumentList',
    component: () => import('@/views/DocumentList.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/files/:id',
    name: 'DocumentItem',
    component: () => import('@/views/DocumentItem.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/files/upload',
    name: 'UploadFiles',
    component: () => import('@/views/UploadFiles.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/files/classification',
    name: 'DocumentClassification',
    component: () => import('@/views/DocumentClassification.vue'),
    beforeEnter: requireCookie,
  },
  // {
  //   path: '/files/validation',
  //   name: 'ValidationForm',
  //   component: () => import('@/views/ValidationForm.vue'),
  //   beforeEnter: requireCookie,
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  document.title = 'Clinic Box';
  store.dispatch('updateActiveRoute', to.path);
  next();
});

export default router;
