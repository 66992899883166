import store from './index';


export const ROLES = {
  ADMIN: 'admin',
  UPLOADER: 'uploader',
  RESEARCH: 'research',
  VERIFIER: 'verifier'
};

export const roleManager = {
  isAdmin() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.ADMIN && user.is_staff === false) : false;
  },
  isAnyAdmin() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.ADMIN) : false;
  },
  isUploader() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.UPLOADER) : false;
  },
  isResearcher() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.RESEARCH) : false;
  },
  isVerifier() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.VERIFIER) : false;
  },
  isStaff() {
    const user = store.getters.user;
    return user ? (user.role === ROLES.ADMIN && user.is_staff === true) : false;
  },
  roleList() {
    return [
      { id: 0, text: 'Admin', value: ROLES.ADMIN }, 
      { id: 1, text: 'Uploader', value: ROLES.UPLOADER },
      { id: 2, text: 'Research', value: ROLES.RESEARCH },
      { id: 3, text: 'Verifier', value: ROLES.VERIFIER },
    ];
  },
};
