export const createNotificationServices = (coreServices) => {
  return {
    getNotifications() {
        return coreServices.get('/notifications/');
    },
    getNotification(notificationId) {
      return coreServices.get(`/notifications/${notificationId}/`);
    },
    markAsRead(notificationId) {
      return coreServices.patch(`/notifications/${notificationId}/read/`, {});
    },
    getNotificationCount() {
        return coreServices.get('/notifications/count/');
    },
  };
};