export const createUserServices = (coreServices) => {
  return {
    /** Delete user
     * @param {int} userId User id to delete
     */
    deleteUser(userId, commentary) {
      return coreServices.delete(`/users/${userId}/`, {commentary});
    },
    getMe() {
      return coreServices.get('/users/me/');
    },
    updateUsername(userId, data) {
      return coreServices.put(`/users/${userId}/update-username/`, data);
    },
    getUserHistory() {
      return coreServices.get('/users/history/');
    },
    /** Get list of users */
    getUsers() {
      return coreServices.get('/users/');
    },
    /** Create a new user
     * @param {Object} data dictionary of the user
     */
    createUser(data) {
      return coreServices.post('/users/', data);
    },
    /** Update a user
     * @param {Object} data dictionary of the user
     * @param {int} userId User id to update
     */
    updateUser(userId, data) {
      return coreServices.put(`/users/${userId}/`, data);
    },
  };
};
