<template>
  <v-app v-cloak>
    <v-main>
      <app-header v-if="isUserLoggedIn /*&& isAppLoaded*/" @toggle-drawer="toggleDrawer" 
      style="position: fixed; top: 0; left: 0; right: 0; z-index: 100;"></app-header>
      <v-navigation-drawer style="margin-top: 65px;" v-model="drawer" v-if="isUserLoggedIn /*&& isAppLoaded*/">
        <nav-bar />
      </v-navigation-drawer>
      <div v-if="$store.getters.isLoading" class="loading-overlay">
        <span class="loader"></span>
      </div>
      <!-- <transition name="screen-fade">
        <router-view :key="$route.fullPath" :style="appBarStyle"></router-view>
      </transition> -->
      <router-view :key="$route.fullPath" :style="appBarStyle"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { getCookie, setCookie } from './utils/cookies';

export default {
  name: 'App',
  components: {
    AppHeader,
    NavBar,
  },
  data: () => ({
    drawer: true
  }),
  mounted() {
    if (getCookie('accessToken')) {
      this.$store.dispatch('validateToken');
    }
    this.drawer = getCookie('drawer', Boolean) || false;
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    /*isAppLoaded() {
      return this.$store.getters.isAppLoaded;
    },*/
    appBarStyle() {
      if (this.isUserLoggedIn /*&& this.isAppLoaded*/) {
        return {
          marginTop: '65px'
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      setCookie('drawer', this.drawer);
    },
  },
};
</script>

<style lang="scss">
.screen-fade-enter-active {
  transition: opacity 0.35s ease-in;
}
.screen-fade-leave-active {
  transition: opacity 0.15s ease-out;
}
.screen-fade-enter, .screen-fade-enter-from {
  opacity: 0;
}
.screen-fade-enter-to {
  opacity: 1;
}
.screen-fade-leave-active, .screen-fade-leave-to {
  opacity: 1;
}
.screen-fade-leave-to {
  opacity: 0;
}

@keyframes fadeAndBlurIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(4px);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  animation: fadeAndBlurIn 0.35s ease forwards;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 92px;
  height: 92px;
  color: #fff;
}

.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(63deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: rgba(19, 55, 71, 0.5);
  transform: rotateY(63deg);
  animation-delay: .2s;
}


@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

@import '@/assets/scss/_main';

</style>
