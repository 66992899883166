import { createStore } from 'vuex'
import { createCoreServices } from '@/services/coreService';
import { createUserServices } from '@/services/userService';

export default createStore({
  state: {
    user: null,
    tokens: null,
    study: null,
    isLoading: false,
    isAppLoaded: false,
    activeRoute: '/',
  },
  mutations: {
    resetState(state) {
      state.user = null;
      state.isAppLoaded = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    setTokens(state, tokens) {
      state.tokens = tokens;
    },
    setStudy(state, study) {
      state.study = study;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAppLoaded(state) {
      state.isAppLoaded = true;
    },
    setActiveRoute(state, route) {
      state.activeRoute = route;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setTokens({ commit }, tokens) {
      commit('setTokens', tokens);
    },
    setStudy({ commit }, study) {
      commit('setStudy', study);
    },
    setLoading({ commit }, isLoading) {
      commit('setLoading', isLoading);
    },
    setAppLoaded({ commit }) {
      commit('setAppLoaded');
    },
    validateToken({ commit }) {
      const coreServicesInstance = createCoreServices();
      const userServicesInstance = createUserServices(coreServicesInstance);
      userServicesInstance.getMe().then(response => {
        commit('setUser', response.data);
        commit('setAppLoaded');
      }).catch(error => {
        console.error('Token validation failed', error);
        this.$router.push({ name: 'Login'});
      });
    },
    updateActiveRoute({ commit }, route) {
      commit('setActiveRoute', route);
    }
  },
  getters: {
    user: state => state.user,
    tokens: state => state.tokens,
    study: state => state.study,
    isLoading: state => state.isLoading,
    isUserLoggedIn: state => !!state.user,
    isAppLoaded: state => state.isAppLoaded,
    activeRoute: state => state.activeRoute
  }
});