import Cookies from 'js-cookie';

function transformToHuman(val, t) {
  if ([undefined, null].includes(val)) return val;
  if (t === Boolean) return (val.toLowerCase() === 'true');
  if (t === Number) return (parseInt(val, 10));
  return val;
}

export function getAccessTokenExpiration() {
  const now = new Date();
  const expiration = new Date(now.getTime() + 60 * 60000 * 1); // 1h
  return expiration;
}

export function getRefreshTokenExpiration() {
  const now = new Date();
  const expiration = new Date(now.getTime() + 7 * 24 * 60 * 60000); // 7 dias
  return expiration;
}

export function setCookie(name, value, expiration) {
  // eslint-disable-next-line
  if (!expiration) expiration = getRefreshTokenExpiration();
  // eslint-disable-next-line
  expiration = '; expires=' + expiration.toUTCString();
  document.cookie = name + '=' + (value || '') + expiration + '; path=/;';
}

export function getCookie(name, t = String) {
  // const nameEQ = name + '=';
  // const ca = document.cookie.split(';');
  // for (let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   while (c.charAt(0) === ' ') c = c.substring(1, c.length);
  //   if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  // }
  // return null;
  const val = Cookies.get(name);
    if (!val) return null;
    return transformToHuman(val, t);
}

export function removeCookies() {
  document.cookie.split(';').forEach((c) => {
    document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  });
  localStorage.clear();
}