<template>
  <v-card class="mx-auto my-0 py-0 card-style">
    <v-layout>
      <v-app-bar color="white">
        <template v-slot:image>
          <v-img color="white"></v-img>
        </template>
        <template v-slot:prepend>
          <v-app-bar-nav-icon color="primary" @click="$emit('toggle-drawer')"></v-app-bar-nav-icon>
        </template>
        <v-app-bar-title class="datalake-title">
          <router-link class="" to="/home">
            <v-img src="@/assets/img/logo_color.png" width="130"></v-img>
          </router-link>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <span v-if="userName" class="datalake-title mr-5 text-secondary">{{ userName }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" :to="{ name: 'UserProfile' }" class="appbar-style mx-1" plain v-bind="props">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>Perfil de Usuario</span>
        </v-tooltip>
        <v-tooltip bottom v-if="uploadEnable">
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" class="appbar-style mx-1" :to="{ name: 'UploadFiles' }" plain v-bind="props">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>Subir Archivo</span>
        </v-tooltip>
        <v-tooltip bottom v-if="this.$route.name !== 'DocumentClassification' && uploadEnable">
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" id="classify-file-app-header-btn" v-bind="props"
              class="appbar-style" :to="{ name: 'DocumentClassification' }" plain>
              <v-icon>mdi-file-document-arrow-right-outline</v-icon>
            </v-btn>
          </template>
          <span>Classify</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" :to='{ name: "Inbox" }' plain class="appbar-style mx-1" v-bind="props">
              <v-badge :color="inboxNotifications > 0 ? 'red accent-5' : 'transparent'"
                :content="inboxNotifications !== 0 ? inboxNotifications : ''">
                <v-icon>mdi-email</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Notificaciones</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" @click="logout" v-bind="props">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Cerrar Sesión</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { useRouter } from 'vue-router';
import { createCoreServices } from '@/services/coreService';
import { createAuthServices } from '@/services/authServices';
import { createNotificationServices } from '@/services/notificationServices';
import { mapGetters } from 'vuex';
import { roleManager } from '@/store/roleManager';
import { removeCookies } from '@/utils/cookies';


export default {
  name: 'AppHeader',
  components: {},
  setup() {
    const router = useRouter();
    const coreServices = createCoreServices();
    const authServices = createAuthServices(coreServices);
    const notificationServices = createNotificationServices(coreServices);

    const logout = () => {
      authServices.logout()
        .then(() => {
          removeCookies();
          router.push({ name: 'Start' });
        })
        .catch();
    };

    return { logout, notificationServices };
  },
  data() {
    return {
      uploadEnable: false,
      // verifierEnable: false,
      inboxNotifications: 0,
    };
  },
  async mounted() {
    this.uploadEnable = roleManager.isUploader();
    // this.verifierEnable = roleManager.isVerifier();
    try {
      const nc = await this.notificationServices.getNotificationCount();
      this.inboxNotifications = this.inboxNotifications = nc.data['count'];
    } finally {
      console.log();
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters(['user']),
    userName() {
      return this.user ? `${this.user.first_name}` : '';
    },
  },
};
</script>

<style scoped>
  .card-style {
      border-radius: 0;
      margin-top: 0 !important;
  }
  .registered-user {
    pointer-events: none;
  }
  .v-card__subtitle {
    padding: 0;
  }
  .subtitle-description {
    padding-bottom: 16px !important;
  }
</style>