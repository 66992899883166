import { getCookie } from '@/utils/cookies';
 
export const createAuthServices = (coreServices) => {
  return {
    async login(data) {
      return coreServices.post('/users/login/', data, '');
    },
    logout() {
      return coreServices.post('/users/logout/', { refresh: getCookie('refreshToken') });
    },
    forgotPassword(formData) {
      const header = coreServices.getRequestHeader();
      delete header.Authorization;
      return coreServices.post('/users/forgot-password/', formData, header);
    },
    confirmForgotPassword(formData) {
      const header = coreServices.getRequestHeader();
      delete header.Authorization;
      return coreServices.post('/users/forgot-password/confirm/', formData, header);
    },
    // setNewPassword(formData) {
    //   const headers = coreServices.getRequestHeader();
    //   delete headers.Authorization;
    //   return coreServices.post('/users/password/reset/confirm/', formData, headers);
    // },
    // setNewPasswordUpdated(formData) {
    //   return coreServices.post('/users/set-password-updated/', formData, coreServices.getRequestHeader());
    // },
    // resetPassword(formData) {
    //   const header = coreServices.getRequestHeader();
    //   delete header.Authorization;
    //   return coreServices.post('/users/password/reset/', formData, header);
    // },
    changePassword(formData) {
      return coreServices.post('/users/update-password/', formData, coreServices.getRequestHeader());
    },
    updateExpiredPassword(formData) {
      return coreServices.post('/users/update-expired-password/', formData);
    },
  }
}